a {
  text-decoration: none;
  color: blue;
}

a:visited {
  color: blue;
}

a:active {
  color: blue;
}

a:hover {
  color: blue;
}

.logoutMessage {
  margin: 30vh auto 0;
  width: 20vw;
  border: 1px solid black;
  text-align: center;
  padding: 5vh 5vw;
  line-height: 1.5;
}
