* {
  box-sizing: border-box;
}

.modal__overlay {
  position: fixed;
  background-color: #dfe0e1cc;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 10000;
}

.modal {
  position: fixed;
  width: calc(100vw - 80px);
  height: calc(100vh - 120px);
  margin: 60px 40px;
  top: 0;
  z-index: 10001;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.15);
}

.modal__header select {
  font-size: 16px;
  padding: 5px;
  max-width: 100%;
}

.modal__close-btn button {
  background-color: transparent;
  color: white;
  padding: 8px 20px;
  border: 0;
  margin-top: -16px;
}

.modal__close-btn button:hover,
.modal__close-btn button:active {
  background-color: #036a85;
}

.modal__inner {
  background-color: #fff;
  position: relative;
  padding: 40px;
  height: calc(100vh - 120px);
}

.modal__header {
  background-color: #091a2f;
  color: white;
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}
.modal__headerTitle {
  width: 95%;
}

.modal__headerTitle h1 {
  margin: 0;
  font-size: 22px;
}

select,
option {
  font-family: 'Lato', sans-serif;
}

.button {
  background: #036a85;
  color: #fff;
  border-radius: 10px;
  font-size: 16px;
  line-height: 16px;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  border: 0;
  margin: auto;
  margin-right: 10px;
  padding: 10px 20px;
}
.button[disabled] {
  opacity: 0.5;
}
